
#digistorm.businesses * {
    box-sizing: border-box;
}

.wrapper {
    width:100%;
    padding:10px;
    box-sizing:border-box;
}

#digistorm.businesses {

    width:100%;
    padding:10px 15px;
    box-sizing: border-box;

}

#digistorm.businesses .business-intro {
    text-align:center !important;
}

#digistorm.businesses .business-intro-information {
    text-align:center !important;
}



#digistorm.businesses .business {
    width:100%;

}

#digistorm.businesses .business-container {
    display:none;
}

#digistorm.businesses .business-toolbar {
    margin-bottom:10px;
}

#digistorm.businesses .business-categories {

    margin: 0 auto;
    width:660px;
    max-width:100%;

}

#digistorm.businesses .business-categories .business-category{

    border:1px solid #ccc;
    float:left;
    width:188px;
    min-height:150px;
    margin:5px 10px;
    padding:10px;
    position:relative;
    overflow:hidden;
    border-radius:2px;

}

#digistorm.businesses .business-categories .business-category .gradient-background {

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:150px;
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );

}

#digistorm.businesses .business-categories .business-category .category-logo {
    position:absolute;
    top:0;
    left:0;
    padding:10px;
    width:100%;
}

#digistorm.businesses .fa {
    width:15px;
}

#digistorm.businesses .business-categories .business-category .category-content {
    position:absolute;
    bottom:0px;
    left:0;
    width:100%;
    padding:10px 0px;
    background-color:#c32032;
    color:white;
    height: 56px;
}

#digistorm.businesses .business-categories .business-category:hover {
    cursor:pointer;
}

#digistorm.businesses .business-categories .business-category:hover .category-content {
    background-color:#c32032;
    opacity: 0.8;
}

#digistorm.businesses .business-categories .business-category .category-content .category-title {
    font-weight:600;
}


#digistorm.businesses .business-categories .business-category .category-content .category-count {
    font-size:12px;
    margin-top:3px;
}

#digistorm.businesses .business-categories .business-category .category-logo img {

    border:none;
    opacity:0.5;
    width:180px;
    max-height:130px;
    margin:0 auto;

}

#digistorm.businesses .business-search {

    /* remove default styling */
    /*border:none;
    background:none;
    outline:none;*/

    /* Custom Styling */

    max-width:200px !important;
    margin:5px auto;

}

#digistorm.businesses .btn-more-info {
    /* remove default styling */
    border:none;
    background:none;
    outline:none;
    padding:0;
    margin:0;
    margin-top:10px;
    text-decoration: none;

    /* Custom Styling */
    cursor:pointer;
}

#digistorm.businesses .business-information {
    padding:10px 0px;
}

#digistorm.businesses .business-row .span p{
    margin:0;
}

#digistorm.businesses .business-row .span a{
    display:block;
}

#digistorm.businesses .business-name {

    font-weight:600;

}

#digistorm.businesses .map-canvas {
    height: 230px;
}

#digistorm.businesses .business-logo {
    max-height:100px;
    max-width:110px;
}


/* Layout stuff */

#digistorm.businesses .business-row {
    width:100%;
}

#digistorm.businesses .business-row:after {
    clear:both;
}

#digistorm.businesses .span {
    padding:10px 15px;
    float:right;
}

#digistorm.businesses .business .business-row .span:first-of-type{
    padding-right:0px;
    @media only screen and (max-width:600px) {
        width: 100%;
    }

}

#digistorm.businesses .business .business-row .span:last-of-type {
    padding-left:0px;
    @media only screen and (max-width:600px) {
        width: 100%;
    }
}

#digistorm.businesses .business-information .span {
    float:left !important;
}

#digistorm.businesses .business-information .span:first-of-type {
    padding-right:15px ;
    padding-left:0px !important;
}

#digistorm.businesses .business-information .span:last-of-type {
    padding-right:0px !important;
    padding-left:15px ;
}


#digistorm.businesses .span1 {
    width:10%;
}

#digistorm.businesses .span2 {
    width:20%;
}

#digistorm.businesses .span3 {
    width:30%;
}

#digistorm.businesses .span4 {
    width:40%;
}

#digistorm.businesses .span5 {
    width:50%;
}

#digistorm.businesses .span6 {
    width:60%;
}

#digistorm.businesses .span7 {
    width:70%;
}

#digistorm.businesses .span8 {
    width:80%;
}

#digistorm.businesses .span9 {
    width:90%;
}

#digistorm.businesses .span10 {
    width:100%;
}

@media all and (max-width: 500px) {
  .business-category {
    width:100% !important;
  }
}

@media only screen and (max-width:768px) {
    .business-category {
        margin:5px 0px !important;
    }
    .span2 {
        width:100% !important;
        text-align:center !important;
    }
    .span8 {
        width:100% !important;
    }
}