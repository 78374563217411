body {
	line-height: 1.4em;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 13px;
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
	color: #c32032;
	font-weight: 300;
}
a, .btn-link{
	color: #c32032 !important;
	&:hover{
		opacity: 0.8;
	}
}

.businesses {
	transition: opacity 1s ease-in-out;
	position: relative;
	min-height: 400px;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url('../images/loading.svg');
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: white;
	}

	&.loaded {
		&:after {
			display: none;
		}
	}

	p {
		color: #666666;
	}
}